// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: '/video',
        name: consts.routerNames.video.main,
        meta: {
            list: 'VideoList',
            mainRouteName: consts.routerNames.video.main,
            hideMap: true,
            layers: consts.defaultSettingsLayers,
        },
        component: () => import('@/views/prometheus/portal/VideoPage.vue')
    },
];

export default routes;