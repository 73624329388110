// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";
const pageClass = 'services-page';

const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: '/services',
        name: consts.routerNames.services.main,
        meta: {
            mainRouteName: consts.routerNames.services.main,
            pageClass,
            layers: consts.defaultSettingsLayers,
            setRoute: {
                serviceId: (route, router, serviceId) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.services.main) {
                        if(this.$isDevelopment) console.warn('services:serviceId', route);
                        return false;
                    }
                    router.push({
                        name: consts.routerNames.services.service,
                        params: {
                            serviceId
                        },
                        query: {...route.query}
                    })
                },
                maintenance: (route, router, name, maintenanceId) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.services.main) {
                        if(this.$isDevelopment) console.warn('maintenance', route);
                        return false;
                    }

                    let routeName =  consts.routerNames.maintenance[name] || consts.routerNames.maintenance.main

                    router.push({
                        name: routeName,
                        params: {
                            maintenanceId,
                        },
                        query: {...route.query}
                    })
                },
                fuelCards: (route, router) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.services.main) {
                        if(this.$isDevelopment) console.warn('maintenance', route);
                        return false;
                    }

                    router.push({
                        name: consts.routerNames?.fuel_cards?.main,
                        params: {},
                        query: {...route.query}
                    })
                },
                driverScoreCards: (route, router) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.services.main) {
                        if(this.$isDevelopment) console.warn('maintenance', route);
                        return false;
                    }

                    router.push({
                        name: consts.routerNames?.driver_score_cards?.main,
                        params: {},
                        query: {...route.query}
                    })
                },
            },
        },
        props: {
          default: (route) => {
              return {
                  serviceId: route?.params?.serviceId
              }
          }
        },
        components: {
            default: () => import('@/views/prometheus/portal/ServicesPage.vue')
        },
        children: [
            {
                path: 'list',
                name: consts.routerNames.services.list,
                meta: {
                    mainRouteName: consts.routerNames.services?.list,
                    parentRouteName: consts.routerNames.services?.list,
                    pageClass: [pageClass, pageClass+'__service'].join(' '),
                    showMap: false,
                },
                props: {
                },
                components: {
                    default: () => import("@/components/services/ServicesList.vue"),
                }
            },
            {
                path: ':serviceId',
                name: consts.routerNames.services.service,
                meta: {
                    mainRouteName: consts.routerNames.services.main,
                    pageClass: [pageClass, pageClass+'__service'].join(' '),
                    showMap: false,
                },
                props: {
                    default: (route) => {
                        return {
                            serviceId: route?.params?.serviceId
                        }
                    }
                },
                components: {
                    default: () => import("@/components/services/Service_item.vue"),
                }
            }
        ],
    },
];

export default routes;